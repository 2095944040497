const isProduction = process.env.NODE_ENV === "production";

const logAnalytics = (functionName, event, properties) => {
  if (!isProduction) {
    console.info(`analytics.js :: ${functionName} :: `, event, properties);
  }
};

export const trackEvent = (event, properties) => {
  logAnalytics("trackEvent", event, properties);

  window?.analytics && window.analytics.track(event, properties);
  window?.posthog && window.posthog.capture(event, properties);
};

export const identify = (properties) => {
  logAnalytics("identify", properties, null);

  window?.analytics && window.analytics.identify(properties);

  if (window?.posthog) {
    const phDistinctId = window.posthog?.get_distinct_id?.();
    phDistinctId && window.posthog.identify(phDistinctId, properties);
  }
};

export const trackBingConversion = (event, properties = {}) => {
  logAnalytics("trackBingConversion", event, properties);

  window.uetq = window.uetq || [];
  window.uetq.push("event", event, properties);
};
